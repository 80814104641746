* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  overflow: hidden;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #112a46;
  background: #acc8e5dc;
}

.home-separator {
  height: 10px;
}

.separator {
  height: 55px;
}

label {
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  margin: auto;
  max-width: 600px;
}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  border-color: rgba(255, 255, 255, 0.1);
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

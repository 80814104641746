.gallery .swiper {
  /* overflow: visible !important; */

  /* width: 100%;
  height: 70%; */
}

.gallery-swiper {
  /* width: 100%; */
}

.gallery-swiper .swiper-slide {
  /* width: 20rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery img {
  display: block;
  object-fit: cover;
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
}

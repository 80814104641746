.aboutart-wrap {
  margin-left: 3.5rem;
  margin-right: 3.5rem;

  display: flex;
  flex-direction: column;
}

.aboutart-wrap h1 {
  align-self: center;
}
